import { css } from "@emotion/core"

import lloydSadd from "../../images/licensed-by.png"
import calgaryTour from "../../images/calgary_tour_partner.jpeg"

import { mq } from "./media-query"

const imgStyle = {
  width: "100%",
  height: "300px",
  margin: "0 auto 1rem auto",
  textAlign: "cover",
  "& img": {
    objectFit: "contain",
    // minHeight: "90%",
    width: "90%",
    height: "90%",

    [mq[1]]: {
      maxWidth: "90%",
    },
  },
}
const extraImgStyle = {
  paddingTop: "1.5rem",
}

const captionStyle = {
  textTransform: "capitalize",
  fontWeight: "700",
  color: "var(--clr-primary-300)",
  fontSize: "1rem",
  letterSpacing: "var(--letter-spacing-sm)",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
}

const cardStyle = css`
  background: none;
  border-radius: var(--border-radius-md);
  text-align: center;
  height: 100%;
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto;
  width: 90%;
  padding: 1.5rem;
  align-self: flex-start;
  min-height: 650px;
  /* display: grid; */
  ${mq[1]} {
    padding-bottom: 0;
  }
`
const textStyle = css`
  text-align: left;
  padding: 0 1rem;
`

const hoverEffect = css`
  &:hover {
    box-shadow: none;
    border: 2px solid var(--clr-primary-300);
    cursor: pointer;
  }
`

export default [
  {
    image: {
      src: lloydSadd,
      alt: "LloydSadd-logo",
    },
    label: "Lloyd Sadd Insurance Brokers",
    caption: "Lloyd Sadd Insurance Brokers",
    capId: "partners.lloydSadd",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    textId: "partners.lloydSadd.text",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open("https://www.lsiins.info", "_blank"),
  },
  {
    image: {
      src: calgaryTour,
      alt: "CalgaryTour-logo",
    },
    label: "Calgary Tour",
    caption: "Calgary Tour",
    capId: "partners.calgaryTour",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    textId: "partners.calgaryTour.text",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open("https://www.calgarytours.ca/", "_blank"),
  },
]
